exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-page-mdx": () => import("./../../../src/pages/index-page.mdx" /* webpackChunkName: "component---src-pages-index-page-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-2022-11-08-aalborg-portland-ccs-project-index-mdx": () => import("./../../../src/pages/news/2022-11-08-aalborg-portland-ccs-project/index.mdx" /* webpackChunkName: "component---src-pages-news-2022-11-08-aalborg-portland-ccs-project-index-mdx" */),
  "component---src-pages-news-2022-11-08-carboncuts-ccs-valuation-index-mdx": () => import("./../../../src/pages/news/2022-11-08-carboncuts-ccs-valuation/index.mdx" /* webpackChunkName: "component---src-pages-news-2022-11-08-carboncuts-ccs-valuation-index-mdx" */),
  "component---src-pages-news-2022-11-08-geothermal-prospect-evaluation-study-index-mdx": () => import("./../../../src/pages/news/2022-11-08-geothermal-prospect-evaluation-study/index.mdx" /* webpackChunkName: "component---src-pages-news-2022-11-08-geothermal-prospect-evaluation-study-index-mdx" */),
  "component---src-pages-news-2022-11-08-true-energy-ev-charging-index-mdx": () => import("./../../../src/pages/news/2022-11-08-true-energy-ev-charging/index.mdx" /* webpackChunkName: "component---src-pages-news-2022-11-08-true-energy-ev-charging-index-mdx" */),
  "component---src-pages-news-2022-11-08-welltec-value-proposition-tool-index-mdx": () => import("./../../../src/pages/news/2022-11-08-welltec-value-proposition-tool/index.mdx" /* webpackChunkName: "component---src-pages-news-2022-11-08-welltec-value-proposition-tool-index-mdx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-decisionriskanalytics-com-decisionriskanalytics-com-src-pages-index-page-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/decisionriskanalytics.com/decisionriskanalytics.com/src/pages/index-page.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-decisionriskanalytics-com-decisionriskanalytics-com-src-pages-index-page-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-decisionriskanalytics-com-decisionriskanalytics-com-src-pages-news-2022-11-08-aalborg-portland-ccs-project-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/decisionriskanalytics.com/decisionriskanalytics.com/src/pages/news/2022-11-08-aalborg-portland-ccs-project/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-decisionriskanalytics-com-decisionriskanalytics-com-src-pages-news-2022-11-08-aalborg-portland-ccs-project-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-decisionriskanalytics-com-decisionriskanalytics-com-src-pages-news-2022-11-08-carboncuts-ccs-valuation-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/decisionriskanalytics.com/decisionriskanalytics.com/src/pages/news/2022-11-08-carboncuts-ccs-valuation/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-decisionriskanalytics-com-decisionriskanalytics-com-src-pages-news-2022-11-08-carboncuts-ccs-valuation-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-decisionriskanalytics-com-decisionriskanalytics-com-src-pages-news-2022-11-08-geothermal-prospect-evaluation-study-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/decisionriskanalytics.com/decisionriskanalytics.com/src/pages/news/2022-11-08-geothermal-prospect-evaluation-study/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-decisionriskanalytics-com-decisionriskanalytics-com-src-pages-news-2022-11-08-geothermal-prospect-evaluation-study-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-decisionriskanalytics-com-decisionriskanalytics-com-src-pages-news-2022-11-08-true-energy-ev-charging-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/decisionriskanalytics.com/decisionriskanalytics.com/src/pages/news/2022-11-08-true-energy-ev-charging/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-decisionriskanalytics-com-decisionriskanalytics-com-src-pages-news-2022-11-08-true-energy-ev-charging-index-mdx" */),
  "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-decisionriskanalytics-com-decisionriskanalytics-com-src-pages-news-2022-11-08-welltec-value-proposition-tool-index-mdx": () => import("./../../../src/pages/news/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/decisionriskanalytics.com/decisionriskanalytics.com/src/pages/news/2022-11-08-welltec-value-proposition-tool/index.mdx" /* webpackChunkName: "component---src-pages-news-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-decisionriskanalytics-com-decisionriskanalytics-com-src-pages-news-2022-11-08-welltec-value-proposition-tool-index-mdx" */)
}

